.dropify-wrapper .dropify-preview .dropify-render img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.dropify-wrapper {
  @apply mt-1.5 rounded border-2 border-dashed border-slate-300 bg-transparent dark:border-navy-450;
}

.dropify-wrapper .dropify-message span.file-icon {
  @apply text-slate-500   dark:text-navy-200;
}
.dropify-message {
  @apply font-inter text-slate-500  antialiased dark:text-navy-200;
}

.dropify-wrapper .dropify-preview .dropify-render img {
  -webkit-transform: translate(0, 0%);
  transform: translate(0, 0%);
}

.dark {
  .dropify-wrapper:hover {
    background-size: 30px 30px;
    background-image: -webkit-linear-gradient(
      135deg,
      #192132 25%,
      transparent 25%,
      transparent 50%,
      #192132 50%,
      #192132 75%,
      transparent 75%,
      transparent
    );
    background-image: linear-gradient(
      -45deg,
      #192132 25%,
      transparent 25%,
      transparent 50%,
      #192132 50%,
      #192132 75%,
      transparent 75%,
      transparent
    );
    -webkit-animation: stripes 2s linear infinite;
    animation: stripes 2s linear infinite;
  }
}

.dropify-errors-container,
.dropify-infos-inner {
  @apply font-inter;
}

.dropify-wrapper .dropify-clear {
  @apply rounded border border-white/10 bg-white/20 font-inter font-normal text-white backdrop-blur hover:bg-white/30 focus:bg-white/30;
}

.dropify-wrapper .dropify-preview .dropify-infos {
  background: none;
  @apply bg-gradient-to-b from-[#192132cb] via-[#19213288] to-[#1921321f];
}
