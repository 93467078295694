.tabs-wrapper {
  @apply overflow-x-auto rounded-lg bg-slate-200 text-slate-600 dark:bg-navy-800 dark:text-navy-200;
}

.tabs-list {
  @apply grid grid-flow-col px-1.5 py-1;
}

.tab-active {
  @apply bg-white shadow dark:bg-navy-500 dark:text-navy-100;
}

.tab-normal {
  @apply hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100;
}

.tab {
  @apply shrink-0 px-3 py-1.5 font-medium;
}
