.q-input {
  @apply mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 text-slate-700 shadow-sm placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary disabled:pointer-events-none disabled:select-none disabled:border-none disabled:bg-zinc-100 dark:border-navy-450 dark:text-navy-50 dark:hover:border-navy-400 dark:focus:border-accent dark:disabled:bg-navy-600;
}

.q-input-icon-holder {
  @apply pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent;
}

.q-input-icon {
  @apply h-5 w-5 transition-colors duration-200;
  margin-top: 5px;
}

.q-input-group {
  @apply relative mt-1.5 flex;
}
