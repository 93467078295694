.swal2-modal {
  font-family: revert;
  @apply rounded-lg bg-white px-4 py-10 shadow-xl dark:bg-navy-700 sm:px-5 #{!important};

  .swal2-title {
    @apply text-slate-600 dark:text-navy-200;
    font-weight: 500;
    margin: 0 0 0.1em;
  }

  .swal2-content {
    @apply text-slate-400 dark:text-navy-300;
    font-weight: 400;
    font-size: 17px;
  }

  .swal2-buttonswrapper {
    margin-top: 2rem;
    .q-btn-default {
      background: transparent;
    }

    button {
      margin: 0px 10px;
    }
  }
}
