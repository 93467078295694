.main-sidebar {
  li {
    a {
      @apply flex items-center space-x-2 rounded-lg px-2.5 py-2.5 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100;
      font-size: 0.8rem;
      line-height: 0.875rem;

      &.expanded {
        @apply rounded-t-lg rounded-b-none bg-slate-100 dark:bg-navy-600;
      }
    }

    &.navigation__active {
      @apply rounded-lg;
      border: 1px solid rgba(var(--main-color-rgb), 1);

      a {
        @apply text-primary hover:text-primary;
        background-color: rgba(var(--main-color-rgb), 0.2);
      }

      .q-sidebar-icon {
        @apply text-primary group-hover:text-primary;
      }

      .collapse-content {
        background-color: rgba(var(--main-color-rgb), 0.1);

        li {
          border: 0;
          border-radius: 0;

          &.navigation__active {
            a {
              font-weight: 700;
            }
          }

          a {
            background: none;
            &:hover {
              background: none;
            }
          }
        }
      }
    }

    .collapse-content {
      @apply rounded-b-lg bg-slate-100 dark:bg-navy-600;

      a {
        @apply flex items-center space-x-2 rounded-lg px-2.5 py-2.5 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100;
        font-size: 0.6875rem;
        line-height: 0.875rem;
      }
    }
  }
}

.q-sidebar-icon {
  @apply h-6 w-6 text-primary-300 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-primary-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200;
}
