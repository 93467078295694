.ql-toolbar.ql-snow,
.ql-toolbar {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  @apply mt-1.5 border-slate-300  dark:border-navy-450;
}

.dark .ql-toolbar.ql-snow .ql-stroke {
  stroke: rgba(255, 255, 255, 0.65);
}

.ql-container.ql-snow,
.ql-container {
  @apply border-slate-300 shadow-sm dark:border-navy-450 dark:shadow-navy-800;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.ql-editor {
  @apply font-inter;
}

.ql-container {
  @apply border-slate-300  dark:border-navy-450;
}
