.select2 {
  margin-top: 0.375rem !important;
  width: 100% !important;
}

.select2-container--default .select2-selection--single {
  @apply h-[39px] rounded-lg border border-slate-300 bg-transparent px-3 py-2 shadow-sm transition-all hover:border-slate-400 dark:border-navy-450 dark:shadow-navy-800 dark:hover:border-navy-400;
}

.select2-container--default .select2-selection--multiple {
  @apply h-auto min-h-[39px] rounded-lg border border-slate-300 bg-transparent px-3 py-2 shadow-sm transition-all hover:border-slate-400 dark:border-navy-450 dark:shadow-navy-800 dark:hover:border-navy-400;
}

.select2-container--default.select2-container--disabled
  .select2-selection--single {
  @apply bg-zinc-100 dark:bg-navy-600;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 20px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  line-height: 15px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  @apply border-slate-300 bg-slate-100 text-xs text-slate-800 dark:border-navy-500 dark:bg-navy-600 dark:text-navy-100;
  padding: 3px 5px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin-right: 4px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  @apply text-slate-700 dark:text-navy-50;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 35px;
  position: absolute;
  top: 1px;
  right: 3px;
  width: 20px;
}

.select2-dropdown {
  @apply border border-slate-300 shadow-md dark:border-navy-450 dark:bg-navy-600;
}

.select2-search--dropdown {
  padding: 12px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  @apply rounded border border-slate-300 px-3 py-2 shadow-sm outline-none focus:border-primary dark:border-navy-450 dark:bg-navy-600;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  @apply text-white;
  background-color: rgba(var(--main-color-rgb), 0.2) !important;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  @apply bg-primary-100 text-white dark:bg-primary-700;
}

.select2-results__option {
  padding: 6px 12px;
}
