textarea.form-control,
input.form-control {
  @apply mt-1.5 w-full rounded-lg  border border-slate-300 bg-transparent px-3 py-2 text-slate-700 shadow-sm transition-all placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary focus:outline-none disabled:pointer-events-none disabled:select-none disabled:border-none disabled:bg-zinc-100 dark:border-navy-450 dark:text-navy-50 dark:shadow-navy-800 dark:hover:border-navy-400 dark:focus:border-accent dark:disabled:bg-navy-600;
}

.form-group {
  display: block;

  span {
    display: block;
  }

  label {
    @apply text-xs;
  }
}

.q-radio {
  @apply h-5 w-5 rounded-full border-slate-400/70 checked:border-primary checked:bg-primary hover:border-primary focus:border-primary disabled:pointer-events-none disabled:border-slate-300 disabled:opacity-60 dark:border-navy-400 dark:checked:border-accent dark:checked:bg-accent dark:hover:border-accent dark:focus:border-accent dark:disabled:border-navy-450;
}

.q-checkbox {
  @apply h-5 w-5 rounded border-slate-400/70 checked:border-primary checked:bg-primary hover:border-primary focus:border-primary disabled:pointer-events-none disabled:border-slate-300 disabled:opacity-60 dark:border-navy-400 dark:checked:border-accent dark:checked:bg-accent dark:hover:border-accent dark:focus:border-accent dark:disabled:border-navy-450;
}

.q-switch {
  @apply h-5 w-10 rounded-full border border-slate-400/70 bg-slate-100 before:rounded-full before:bg-slate-300 checked:border-primary checked:before:bg-primary dark:border-navy-500 dark:bg-navy-900 dark:before:bg-navy-400 dark:checked:border-accent dark:checked:before:bg-accent;
}

input[type="search"].form-control {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAABFVJREFUWEfFl19oXEUUxr8ze0MxVUl8i/oQo0UNVgoKiuKDD6I21milUsGSmL0bbRFNunM3iSKmoI3ZmZtdUSNm7yappVhbUPFP65OKBBQVWtEaEAwVrEUUa9JWaXL3Hpnt7tLd7ibbZqvzNnfOfOc3M2fOnEv4nxudq//OnkSDZfmNHIgGhDL1ViCOUSBmxam52dHRwRPnqlcVQM5pJ4AOAGsWcfIxAzvS2tldLciSALYcHmQOdRBxc5Eo0QyAP8B8PYBLSsa+JMJoKi53LgWyKIAt1fsA1hkRBnYKEp8QB9MnT62Y3vXKU3N58c39iRZ/fqGVBRmYXgBNZoyIXk4p2bMYREUAW6ojAC4HMA+gz9NOcqnVmPHOnqHmOst6lYG2HPj+tHbWVppbFsCWahrAdQA+87RzZzWOS226tsY7haCJHMRIWjvRcjpnAdhSJQD0MLA7rZ1Hzsd5fk4ueI9lIZgjaTfmleoVAZiAA8TzIPztKWflcpzn54ajww8QiXezfeI2T8X2nalbADC0oVDmgIl2EqI9FY+aAKxJy+8qgLSnHbssgC2VidYEMY2nXBmuieecSO4oPgXQLPz51rHks0fz+oUdsKU6YJJMpbNaLlA4Gp8gok4QP+mp2GtFAJv7hxoXfOtP81Ew3THmyqnlOiydH5FqgIHtYPY8NxYpAujqTbSIkP9TFiCUaRgb7p+tNYAtVTuA90qvdvYI7Ki+CcTfAPjZ005xyq0RSVfvUIsIWWaRRzztXFm0A2EnfjsxTYHoK0/JW2rks0ime/CN+uDE3EkC/klpp74I4PGobs0QHwJw3NPOpRcCoCs2fLMIxNcgOuQpeUMRwBO9I1f4ocwv5mOdZV39+ku95qWraQtH9SYifhPAB5527i8C2LJl8OL5+pXHs8kq4HWpkdiHNfUOICLVdgYGSl/IM/PAfgD3AIh52lG1BrClMjegHSTaPBUtpOMCQFiqjQS8BeCo7/u3TSYHDtcKIuxom5hTzHQ47cqryqbi7HV09BdgvpWAj1Laua8WAN3PJJqC+WyOuYiIXkgp+VxFgEhMb+IgGygIAn5sfCQ2uVwIW6pvAdwI8O9BENw1PtJv+oV2Vj0QcXSSmZ82Fr5vNU4me/86X4jC8559ienRlCt3lWqVrYjCUu0j4F5jzBw8mHb7TABV3XKvn6mM785OYpKeK91yAhVrwrBULgFbc5OSvm9tq2Y3TAECEjsIOJ3QSGz0VPRtsxue7husagfyRuFo3CaiVK5/kJkPCqIfGfghyPjfLYSs31YIsZqCYDUTX0sQq5j5dJIhmgkCdIy7cirsxDcQ0x4w7fVc+fCiMVBKaDvxtWBaD+AhAA1VnMOvpp4EMJHWzvd5ezuq94B4QynEkj8meYHunhebgrq69QiwBkTXAFhFwGVsflCYZ4hohgN87rnynUqQ5SCqBqhi5VWZFCAQbDMx8Z8DGEoTE2kV25sNlaqwL6DRvxCTtjCSMWeWAAAAAElFTkSuQmCC");
  background-position: 9px 9px !important;
  background-size: 18px;
  padding-left: 2.2rem !important;
  background-repeat: no-repeat;
}
