.wave {
  display: inline-block;
  -webkit-font-kerning: none;
  font-kerning: none;
  transform: translate3d(0px, 0px, 0.1px);
  transform-origin: 60% 70%;
  user-select: none;
  animation: wave-animation 0.5s 15 ease-in-out;
  animation-play-state: running;
}

@keyframes wave-animation {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
}
