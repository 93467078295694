.q-btn-submit {
  @apply min-w-[7rem] bg-primary font-medium text-white hover:bg-primary-600 focus:bg-primary-700 active:bg-primary-700;
}

.btn {
  @apply gap-2;
}

a.btn-bordered-primary,
a.btn-bordered-info,
a.btn-bordered-success,
a.btn-bordered-warning,
a.btn-bordered-error {
  @apply px-2 text-xs sm:px-5 sm:text-sm;
}
button.btn-bordered-primary,
button.btn-bordered-info,
button.btn-bordered-success,
button.btn-bordered-warning,
button.btn-bordered-error {
  @apply px-2 sm:px-5;
}

.q-btn-default {
  @apply bg-slate-150 font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90;
}

.q-btn-success {
  @apply bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90;
}

.btn-bordered-primary {
  @apply border border-primary font-medium text-primary;
  background-color: rgba(var(--main-color-rgb), 0.1);
  &:hover {
    background-color: rgba(var(--main-color-rgb), 0.3);
  }
  &:focus {
    background-color: rgba(var(--main-color-rgb), 0.4);
  }
  &:active {
    background-color: rgba(var(--main-color-rgb), 0.5);
  }
}

.btn-bordered-info {
  @apply border border-info/30 bg-info/10 font-medium text-info  hover:bg-info/20 focus:bg-info/20 active:bg-info/25;
}

.btn-bordered-success {
  @apply border border-success/30 bg-success/10 font-medium text-success hover:bg-success/20 focus:bg-success/20 active:bg-success/25;
}

.btn-bordered-warning {
  @apply border border-warning/30 bg-warning/10 font-medium text-warning hover:bg-warning/20 focus:bg-warning/20 active:bg-warning/25;
}

.btn-bordered-error {
  @apply border border-error/30 bg-error/10 font-medium text-error hover:bg-error/20 focus:bg-error/20 active:bg-error/25;
}

.btn:disabled {
  opacity: 0.5;
}

.btn-bordered-warning:disabled {
  border: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  opacity: 1;
}

/* .action-delete {
  @apply bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90;
} */
