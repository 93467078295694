.badge {
  @apply flex h-7 max-w-[100px] items-center justify-center rounded-lg bg-slate-100 p-1 font-semibold leading-none text-slate-500;
}

.badge-success {
  @apply bg-success/30 text-success;
}

.badge-error {
  @apply bg-warning/30 text-warning;
}

.badge-error {
  @apply bg-error/30 text-error;
}

.q-label-wrapper {
  @apply hidden items-center gap-6 px-4 pb-2 font-inter text-xs text-slate-400 dark:text-navy-300 sm:px-5 xl:grid;
}

.q-list-label {
  @apply block font-inter text-xs text-primary-600 xl:hidden;
}
