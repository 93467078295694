.q-table {
  @apply min-w-full overflow-x-auto rounded-lg border border-slate-200 dark:border-navy-600;

  table {
    @apply w-full bg-white text-left dark:bg-navy-700;

    thead {
      th {
        @apply border border-t-0 border-slate-200 px-3 py-3 text-xs font-semibold uppercase text-slate-800 dark:border-navy-500 dark:text-navy-100 lg:px-5;
      }
    }

    tbody {
      td {
        @apply border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5;
      }
    }
  }
}

.q-table-no-border {
  @apply min-w-full overflow-x-auto rounded-lg;

  table {
    @apply w-full bg-white text-left dark:bg-transparent;

    thead {
      th {
        @apply px-3 py-3 font-semibold uppercase text-slate-800 dark:text-navy-100 lg:px-5;
      }
    }

    tbody {
      td {
        @apply px-3 py-3 lg:px-5;
      }
    }
  }
}
