.q-dropdown {
  &__item {
    @apply flex items-center space-x-3 py-2 px-4 tracking-wide outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600;

    &__icon {
      @apply flex h-8 w-8 items-center justify-center rounded-lg;
    }

    &__text {
      @apply font-medium text-slate-700 transition-colors group-hover:text-primary group-focus:text-primary  dark:text-navy-100;
    }

    &__subttext {
      @apply text-xs text-slate-400 line-clamp-1 dark:text-navy-300;
    }
  }
}
