.flatpickr-input {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAABDxJREFUWEfFl11oHFUUx//n7odNxdJI0yjNg0+CWm21Ba31IdUiVJvd2WRGaKsgtN2drZQqKAgqTVBBUNAidme3LQjVFpxJdnbT6kvVPFg/oNWqVcEH8SFF0xa3KDY22b1H7mZXttmd2Ykkel/mYf7nnN+ce869Zwj/86Kg8VNx49ZoFBfetO0Lfja7DaNragpd2YL9fRDfgQDSmrGGwacINJpx7Zif47RmFBncR6C1Gdc+3Q4iEICp6b0APgYwBmCojdO9AJR+g+U6Su+7PAGSfX3LROiaZwHcC2BdO0ce7z8D8KmsXHklNzp6sZWmJYAZ618PEvtBuKNqxPgVhBtqGQjC0ttgo+y/ActdVnHk5GzjJoBkfOBuQfR5NS7jLYD2E/Hy+hZYrrPBj8DUdLVV1S1gpvMA7yLCE8pGMt+TKwx/0WjfBGDG9XdA2EaMnZmCc1CJVWWXp3GIwV9arjPYBmCQQHeFI9he75h0XN/BhANgvGsVnEc9AdKavomB9wF8aLnOxiC5DqoxNf0EgAcIeCjjOh/U7a7KgKkZbwC8hyTFM0W7GNR5EF06ZsRYcAGgfZZrP9kSoN7DFdCdB1z7TBDHQTU7NWN1CPzV7LPkqgykE/pZZtyGMvVYx+xzXs53b9q2pBy9skoS91Q1JH7O5m3Vcp7L3GysQJjHifBdJu+s9MiAfpmBDrn8+mgul5v28pbU9AdDoJsk5B9KIyCuA1d+yhRG1D63XMlkMiLO/zZFwGTGdRZ71IDO6oXlOr4npKkZjwOoWK59WOlNzXgMQMhy7bfbdEiT/1lFGAwgldDjgqm7wqimPURYJ4knsnmn8J8ApB/e2ono1EYwdc7UAJcwFT2ROX6ktOAAg7294YnOZQkVnCQ+UgFZ4H4F0V26mB8cGyt7QZhac4bnvAW1UzEB4HT9ulXXNYA14QjyfvPCvAA8ZRgdf01DFR0Ey6/VU5JYpZ6LIjj8um1PLmgGlPP6F88K9E9GFhxABUj299/YGCg3MvKLXwHOtGubGkhpeomApR0RWuyXynaBWr1XWzc5zeqgu5R1nZnuUQ3UKE5pxrcEXkmCNrOE7/A5VwgS6GLJxxh0Nuvat7c+CeOPfAKS6yHxIgkan2sQPz1L7oHAC2Bx0iq8d59XBg4SeLsaRojo1LwCMK9VQwmDDmVde0drgMSASUwZAEct19k6nwCmph8BsIWJ09n8sNUSoNbjZxh8c9C5Pghkw3/Fj4siWN1Y4M0zYWLgOTC9VHM8JIXMBmmxViCqVYUUKQDqX0HdGc9b+eGXG7Utr91UXH+aCK/WhGUilJhxCYC6bC63+Wp113cSYSkzVLuFlZ4Zz2QLzmuzbT3vfTVCCWAvgW8hoIeBa4Oku2Fv/2RgnEE/SGDIa8QL9GumnO6Jbekuh+WKiqws8QMJidDv4bI4t694dCIIcGCAIM7+jeZvOgXaMJfgI/gAAAAASUVORK5CYII=");
  background-position: 9px 9px !important;
  background-size: 18px;
  padding-left: 2.2rem !important;
  background-repeat: no-repeat;
}

.flatpickr-day {
  line-height: 38px;
  border-radius: 0.5rem;
}
