.table-responsive table.table {
  width: 100%;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;

  thead tr {
    border: 0;
  }

  & > tbody > tr:hover > td {
    @apply bg-slate-100 dark:bg-navy-600;
  }

  th {
    @apply relative bg-primary-100 px-3 py-3 font-inter text-xs font-semibold uppercase text-primary-800 transition-all dark:bg-primary-700 dark:text-primary-200 lg:px-4;

    &.sorting,
    &.sorting_asc,
    &.sorting_desc {
      @apply cursor-pointer hover:bg-primary-200 dark:hover:bg-primary-600;
    }

    &:before {
      content: "\00a0 ";
      position: absolute;
      right: 15px;
      width: 15px;
      height: 20px;
      opacity: 0;
      transition: opacity 0.3s;
      background-size: 15px;
      background-repeat: no-repeat;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    &.sorting:before,
    &.sorting_asc:before {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAC7SURBVFhHYxgFo2AUjHjACKWJBun+oVqMjP+nQrko4P9/xuyZG1dfg3KJAkxQmnjA9D8OSDpgw0CHFQNpkgDJIQACGQEhIAsxwIwNaw5AmaNgFBAN8CZCYGLbD6SwJjgSwAFg4nSEsjEA6dlwFIwCKgOUXJAREKDAwMACxLQDzIzMz6auX3kLykV3QPB9oBBNHQCsM0/P2LDWDMpBd0AIqDbzgfBoA4A15srp69fOgHJHwSgYBSMeMDAAABG/Jl4x7axjAAAAAElFTkSuQmCC");
    }

    &.sorting_desc:before {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAANxJREFUWEftkzEOwjAMRe1rsLEwsLPDCUjVou4sBPUCXIGBESkdOAFBBImdzhyAuQehMhNDpYBiVVFBcmbn+/nJRuj5Yc/9QQDEgBhoGVgnqSbCPPJpXo2zu3ePFoBW6R0AJ3EBqDbuNPQCFEk+aqgZxAV41sa52gsQt7E/Xa7gtw1old0AYNpxNyrj7OxTxlcDvQN0nDzo+2/vQNAIHYv+04BWmfcyjLMVVwjbgFbZAQCWvkaEsC3PdsOBYAOs5osxIu29AIRFeTk+ogJwwkNq2QZCQjk1AiAGxMALEm8yITi20ukAAAAASUVORK5CYII=");
    }

    &:first-of-type {
      border-top-left-radius: 0.5rem;
    }

    &:last-of-type {
      border-top-right-radius: 0.5rem;
    }
  }

  tr {
    @apply border border-transparent border-b-slate-200 dark:border-b-navy-500;

    td:first-child {
      @apply font-semibold;
    }
  }

  td {
    @apply px-4 py-3 sm:px-4;
  }
}

#DataTables_Table_0_length {
  width: 50%;
  display: inline-block;
  @apply font-inter;

  select {
    line-height: 1.3rem;
    @apply appearance-none rounded-lg border border-slate-300  bg-transparent px-2 py-1 font-inter text-slate-700 shadow-sm transition-all placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary focus:outline-none disabled:pointer-events-none disabled:select-none disabled:border-none disabled:bg-zinc-100 dark:border-navy-450 dark:text-navy-50 dark:hover:border-navy-400 dark:focus:border-primary dark:disabled:bg-navy-600;
  }
}

#DataTables_Table_0_filter {
  width: 50%;
  display: inline-block;
  text-align: right;
  @apply font-inter;
}

.dataTables_filter input {
  @apply ml-1 rounded-lg border  border-slate-300 bg-transparent px-3 py-2 font-inter text-slate-700 shadow-sm transition-all placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary focus:outline-none disabled:pointer-events-none disabled:select-none disabled:border-none disabled:bg-zinc-100 dark:border-navy-450 dark:text-navy-50 dark:hover:border-navy-400 dark:focus:border-primary dark:disabled:bg-navy-600;
}

.dataTables_filter input[type="search"] {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAABFRJREFUWEfFl11oHFUUx//nZpM0iUoi+NDqQ/yo2mCloKAogj4UtWmyN7szWEVJxDY7q4KpitIHUUH6IjURbXYSq2ktxZSZ3bmJHykIWkRQVGiL1kDBUMG2SMUa2xhdkzlyZ7MhO+4m22at92n3fpzzu+ec+793CP9zo/P13yNl47nZ6qYI+Y2IcD3PVp2hWUyKOkz2O86587VXFkCXlI01iHQR0EnAulJOiHGAGXvsUXe4XJAlASxpvARwJ0DNIaMTAH4BsAbApQVjTF+C0G8rZ+9SIIsCJKU5yuC2nBHeC4hPfPbHa7Mrxt8Y2/d73ngyFruGWbQQaA0zbwWwMjdGr9vK6VkMoiRAUponGLyKgawAnk8pt2+p3ehxS8pmosibzGidmz9mK3dDybQVG7CixjgINwI4aCv3nnIch+dYMtYFiKEgduDXBlT6mWJ2/hWBhDR6CegBY9gecR+8EOf5Nbp4VyByJkgGY0tqxN0VtlcAkCs4vAjgD1u5Dctxnl/bLeNSgLwgEsytAyPpjxbanQfI0VYd0tVOgqKpjDNaCQBtIx9VAr+dUunNRQG6pdEjgF4A79jKfaxSzrUdvblaRD4F0MzCbxnMZE7l7c9HICGNQ1pkSuVquUCWjA8B1EXET6a89M4CgGTrQ01cnf1Vd/pEdw16zufLdRhen5DGNgK2g7DL9twthQBaSHzxQwBQTY2DjjNZcYAOI0oMFT7aQQqS0ryFwd8A+NFWblhyK8ISqKXeJOOEPeJeVRABKxa7E77QYf/KVu5tFfEYMtLd1lYvqmqnCJhOKbe+ACARNVuI+CiAs7ZyL/svABLt8VtJ0NdEOJry3JsKI7DRvBIR/ilQLOFfm8pk9E1X0WZJ8xGA3yXQ+ynltBcAPG6al/h/89mgk9Bme+4HFfUe1JmxnYFt4RtyXgeSUWOMCfcR0XMpz3m10gCWNPQJiIbleB7Aajc2QeA9AKeAmTtspY5XCiIZNTYz4S2Aj9sqfXVRKdadVtT8AsS3E+HDlOdurARAdyy2UuQ0po6AV1LKfaE0wFyh5Cb4j9oqs3u5EFaHcQSMmwGcZppdP+B5R0oCBFGQZh/AT+nff2KmabdSv10oxILrXRf3w7bn7gvbKvoks6Sh7+z7gziAOwZVWhdQ2S13tVcPA3zv3KJnbeXuKGag5JswIeM7CPS0XsRA31+YebmcaMw9QPYACARNMG3qH3H2P9HxwPUc8U+Gvx0WfxXPV28AcZjAhxl0DITvBfnfNjQ0/Dw1lV3LPLMWTDeAsBqMvMhM+ESd+mbVzn3272bG6boaHOh1nOkCIVostolofIMgxHxQnIDGMvJwksHDRP6Q7Xnf6fmB0GWxnghXhCGW/DDJO9THqYopxqB1YFwHxmoiXA7CBJgmGJhg4s8GPDdTDHKradZNZ7XQ5SBEDT7W6SgboIydLzllIYQgcXCnt//YRQXIp4NmxCrtXP+/6ADhMP0DBBS1MK3xuXwAAAAASUVORK5CYII=");
  background-position: 9px 9px !important;
  background-size: 18px;
  padding-left: 2.2rem;
  background-repeat: no-repeat;
  line-height: 1.3rem;
}

.dataTables_empty {
  @apply text-center text-base font-semibold;
}

#DataTables_Table_0_info {
  width: 100%;
  text-align: center;
  display: block;
  @apply font-inter text-xs;
}

#DataTables_Table_0_paginate {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @apply font-inter text-slate-600 dark:text-slate-300;

  .paging_simple_numbers span {
    float: left;
  }

  .ellipsis {
    float: left;
  }

  .paginate_button {
    float: left;
    @apply mx-1 flex h-8 min-w-[2rem] cursor-pointer items-center justify-center rounded-full px-3 leading-tight transition-colors hover:bg-slate-300  focus:bg-slate-300 disabled:pointer-events-none disabled:opacity-20 dark:bg-navy-500 dark:hover:bg-navy-450 dark:focus:bg-navy-450;

    &.disabled {
      @apply opacity-20;
    }

    &.current {
      @apply bg-primary text-white;
    }
  }

  .previous,
  .next {
    @apply flex h-8 min-w-[2rem] cursor-pointer items-center justify-center rounded-full bg-slate-150 px-3 leading-tight transition-colors hover:bg-slate-300  focus:bg-slate-300 disabled:pointer-events-none disabled:opacity-20 dark:bg-navy-500 dark:hover:bg-navy-450 dark:focus:bg-navy-450;

    &.disabled {
      @apply opacity-20;
    }
  }
}

.dt-buttons {
  display: none;
}
div.dt-searchPanes div.pane {
  @apply rounded border border-slate-200 font-inter dark:border-navy-600;

  &.filtering button[type="button"] {
    padding: 2px;
    line-height: 2px;
    height: 25px;
    width: 25px;
    @apply rounded-full border-0 bg-slate-200 transition-all dark:bg-navy-500;
  }

  div.title {
    line-height: 17px;
    padding: 9px 0.6em;
    @apply border-b border-slate-200 bg-slate-100 font-semibold dark:border-navy-600 dark:bg-navy-600;
  }
  div.scroller ul li {
    span.label {
      padding: 0.3em 0.6em;
    }

    &.selected {
      color: initial;
      @apply bg-primary-100;
    }

    &:nth-child(odd) {
      &.selected {
        @apply bg-primary-100;
      }
      @apply bg-slate-50 dark:bg-navy-800;
    }

    span.count {
      @apply rounded bg-slate-100 dark:bg-navy-500;
    }
  }
}
