@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../../public/css/vendor/sweetalert2.min.css";
@import "../../public/css/vendor/select2.min.css";
@import "../../public/css/vendor/animate.min.css";
@import "../../public/css/flags.css";
@import "~quill/dist/quill.snow.css";

@import "../css/base.css";
@import "../css/components.css";

@import "./components/input";
@import "./components/sidebar";
@import "./components/profile";
@import "./components/badge";
@import "./components/tab";
@import "./components/table";
@import "./components/button";
@import "./components/auth";

@import "./vendor/select2";
@import "./vendor/dropify";
@import "./vendor/sweetalert";
@import "./vendor/notify";
@import "./vendor/wave";
@import "./vendor/datatable";
@import "./vendor/quill";
@import "./vendor/flatpickr";

.body-backoffice {
  .card {
    @apply p-4 sm:p-5;
  }

  h4.card-title {
    @apply mb-6 text-lg font-semibold text-slate-600 dark:text-navy-200;
  }

  .export-card {
    @apply flex items-center gap-4 text-center font-semibold transition-all hover:bg-slate-300/20 hover:shadow-lg active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:active:bg-navy-300/25;

    svg {
      @apply h-8 w-8 text-primary;
    }
  }
}

.bg-primary-opacity-10 {
  background-color: rgba(var(--main-color-rgb), 0.1);
}
.bg-primary-opacity-20 {
  background-color: rgba(var(--main-color-rgb), 0.2);
}
.bg-primary-opacity-30 {
  background-color: rgba(var(--main-color-rgb), 0.3);
}
.bg-primary-opacity-40 {
  background-color: rgba(var(--main-color-rgb), 0.4);
}
.bg-primary-opacity-50 {
  background-color: rgba(var(--main-color-rgb), 0.5);
}
.bg-primary-opacity-60 {
  background-color: rgba(var(--main-color-rgb), 0.6);
}
.bg-primary-opacity-70 {
  background-color: rgba(var(--main-color-rgb), 0.7);
}
.bg-primary-opacity-80 {
  background-color: rgba(var(--main-color-rgb), 0.8);
}
.bg-primary-opacity-90 {
  background-color: rgba(var(--main-color-rgb), 0.9);
}
